import Vue from 'vue'
import Element from 'element-ui'
import VueI18n from 'vue-i18n'
// import Cookie from 'js-cookie' // 引入cookie包
import enLocale from 'element-ui/lib/locale/lang/en'
import idLocale from 'element-ui/lib/locale/lang/zh-CN'
//导入自己的语言包
import english from './en'
import idglish from './id'

Vue.use(VueI18n)
//准备语言
const messages = {
    //1. 英文
    en: {
        ...english,
        //导入element-ui里面的国际化语法
        ...enLocale,
    },
    //2. 印尼
    id: {
        ...idglish,
        ...idLocale
    }
}
function getBrowserLanguage() {
    let userLanguage = navigator.language || navigator.userLanguage;
    if (userLanguage != "id" && userLanguage != "id-ID") {
        localStorage.setItem("lang", "en");
    } else {
        localStorage.setItem("lang", "id");
    }
    return localStorage.getItem('lang') || 'en'
}

//2. 通过选项创建 VueI18n 实例
const i18n = new VueI18n({
    // locale: localStorage.getItem('lang') || 'en',
    locale: getBrowserLanguage(),
    // locale: 'id',//选中的语言
    messages,//语言环境
})

//兼容写法
// Vue.use(Element, {
//     i18n: (key, value) => i18n.t(key, value)
// })

//3. 导出i18n 
export default i18n