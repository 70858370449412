import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import './assets/font.css'
import { Swipe, SwipeItem } from 'vant';

Vue.use(Swipe);
Vue.use(SwipeItem);

// 隐藏 右侧浏览器 滚动条
import VueSmoothScroll from "vue2-smooth-scroll";
Vue.use(VueSmoothScroll)

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

import './utils/rem'
import request from './axios/axios.js'
Vue.prototype.$request = request

// 导入less文件
import Index from '../src/assets/css/index.less'
import homebox from './components/Home-Box/homebox.less'
import Header from './components/Header-box/header.less'
import Footer from './components/Footer-box/footer.less'
import Content from './components/Plan-box/contentbox.less'
import Join from './components/Join-box/join.less'
import Service from './components/Service-box/service.less'
import Us from './components/Us-box/usbox.less'
import Login from './components/Login-box/loginbox.less'
import Register from './components/Register-box/registerbox.less'

import i18n from '../src/lang/i18n.js'
// 导入animate动画库
import 'animate.css'
// 导入js文件
import Flexible from '../node_modules/amfe-flexible/index.min.js'

// 注册
Vue.use(Index)
Vue.use(Flexible)
Vue.use(homebox)
Vue.use(Header)
Vue.use(Footer)
Vue.use(Content)
Vue.use(Join)
Vue.use(Service)
Vue.use(Us)
Vue.use(Login)
Vue.use(Register)



Vue.config.productionTip = false

new Vue({
  router,
  i18n,// 挂载
  render: h => h(App)
}).$mount('#app')
