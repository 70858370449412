<template>
  <!-- 广告图 -->
  <div class="banner">
    <div class="new-header">
      <div class="header-contain">
        <!-- 轮播图 -->
        <div class="advise">
          <el-carousel
            arrow="never"
            autoplay
            :interval="5000"
            class="swiper"
            ref="swiper"
            @change="changeSwiper"
          >
            <el-carousel-item
              class="swiper_item"
              v-for="(item, index) in swiperList"
              :key="index"
            >
              <img class="img" :src="item.url" alt="" />
            </el-carousel-item>
          </el-carousel>
          <div class="nav_dot">
            <div
              class="item"
              :class="item.id == activeNav ? 'item_active' : ''"
              v-for="(item, index) in navList"
              :key="index"
              @click="selectNav(item)"
            >
              <img class="img" :src="item.url" alt="" />
              <div class="mask"></div>
            </div>
          </div>
        </div>
        <!-- 介绍 -->
        <div class="introduce">
          <div class="introduce_content">
            <div class="history">
              <h2 class="title">{{ $t("mobile.title") }}</h2>
              <h1 class="title_h1">{{ $t("mobile.name") }}</h1>
              <div class="year">
                <div class="col"><b class="num">2024</b></div>
                <div class="num_text">
                  {{ $t("mobile.info") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  name: "HomeBox",
  components: {
    swiper,
    swiperSlide,
    QRCode,
  },
  data() {
    return {
      //选择导航
      activeNav: 1,
    };
  },
  computed: {
    swiperList() {
      return [
        {
          id: 1,
          url: "/img/home02.png",
        },
        {
          id: 2,
          url: "/img/home03.png",
        },
        {
          id: 3,
          url: "/img/home04.png",
        },
        {
          id: 4,
          url: "/img/home05.png",
        },
      ];
    },
    navList() {
      return [
        {
          id: 1,
          url: "/img/home06.svg",
        },
        {
          id: 2,
          url: "/img/home07.svg",
        },
        {
          id: 3,
          url: "/img/home08.svg",
        },
        {
          id: 4,
          url: "/img/home09.svg",
        },
      ];
    },
  },

  methods: {
    //选择导航
    selectNav(item) {
      this.activeNav = item.id;
      this.$refs.swiper.setActiveItem(item.id - 1);
    },
    //切换轮播
    changeSwiper(value) {
      this.activeNav = value + 1;
    },
  },
};
</script>
