<template>
  <div>
    <!-- 首页导航 -->
    <div class="header-box">
      <headerBox navTable="4"></headerBox>
    </div>
    <div class="banner">
      <div class="new-header">
        <div class="header-contain">
          <!-- 轮播图 -->
          <div class="advise">
            <img class="img" src="/img/home10.svg" alt="" />
          </div>
          <!-- 标题 -->
          <div class="head_line">
            <div class="head_line_content">
              <div class="name">Contact us</div>
              <div class="email">Email：123456@163.com</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import headerBox from "../components/Header-box/header-box.vue";

export default {
  name: "Mobile",
  components: {
    headerBox,
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.advise {
  .img {
    width: 100%;
    height: auto;
    object-fit: contain;
    cursor: default;
  }
}
// 标题
.head_line {
  padding-top: 36px;
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  .head_line_content {
    width: 1200px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    .name {
      font-weight: bold;
      font-size: 32px;
      color: #000000;
    }
  }
}
</style>
