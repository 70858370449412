<template>
  <div>
    <!-- 首页导航 -->
    <div class="header-box">
      <headerBox navTable="2"></headerBox>
    </div>
    <div class="banner">
      <div class="new-header">
        <div class="header-contain">
          <div class="advise">
            <img class="img" src="/img/home11.svg" alt="" />
          </div>
          <!-- 标题 -->
          <div class="head_line">
            <div class="head_line_content">
              <div class="name">{{ $t("mobile.usr_agreement") }}</div>
              <!-- 协议英文 -->
              <template v-if="lang == 'en'">
                <!--StartFragment-->
                <div class="Section0">
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体">EULA</font></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >[ End User License Agreement ]</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      >&nbsp;</span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >END USER LICENSE AGREEMENT IS A LEGAL APPROVAL BETWEEN
                        YOU (USER) WITH GAME PUBLISHER (WILL BE CALLED WITH GAME
                        PUBLISHER). BY PRESSING “AGREE”/”REGISTER”, YOU HAVE
                        AGREED TO FOLLOW THE ENTIRE REGULATIONS AND CONDITIONS
                        RESTRICTED BY GAME PUBLISHER.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >By agreeing this agreement, you (Will be called as
                        USER) legally agree to comply with all applicable
                        regulations for all content / services provided. All
                        Element ( PICTURE , STORY , INFORMATION AND CHARACTER )
                        which relates to the content / content provided by Game
                        Publisher , which is a part of Game Publisher's
                        copyright and business partners. By using the content /
                        services provided by Game Publisher, User automatically
                        approves the rules and approvals set by Game
                        Publisher.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >1.This condition is made for the provision of basic
                        rules concerning the terms of use of all content (online
                        games) provided by Game Publisher. The rights of
                        publication and operational services are fully owned by
                        Game Publisher.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >2.Game Publisher reserves the right to revise and amend
                        this Agreement at any time. Users are required to always
                        check the official website of Game Publisher provided
                        periodically to view and review this legal
                        agreement.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >3.Game Publisher can change some or all of the content
                        / content of the service or the official website to
                        improve the quality of the service with or without
                        notice.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >4.Users who want to use the Game Publisher service must
                        register through the official website with filling the
                        data accurately and correctly. Game Publisher is not
                        responsible for any problems arising in the future as a
                        result of incomplete and incorrect registration of
                        registration data.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >5.Game Publisher is obligated to maintain the
                        confidentiality of all User's personal information and
                        data. Game Publisher does not provide such information
                        to any parties except Game Publisher's business partners
                        in providing content / services to the User.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >6.If there are any problems caused by disruption of
                        services or services that are unsatisfactory due to
                        limitations or lack of facilities, then Game Publisher
                        reserves the right to restrict the service to the User
                        until the problem is resolved.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >7.All elements (items, characters) in Game Publisher
                        service are fully owned by Game Publisher. Any purchase
                        and use of such elements is NOT an endorsement of the
                        User's property, but ONLY is the right to use and use as
                        long as the User uses the service.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >8.If there are any problem with the service provided,
                        then Game Publisher shall be obliged to inform the
                        repair schedule to the User through the official website
                        of Game Publisher. USER is entitled to provide
                        information if it finds a problem from the services
                        provided, in a way or container recommended by Game
                        Publisher.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >9.If User wants to change personal information
                        (registration code, email, secret code), then User is
                        obligated to change it through the official website and
                        or through any other way provided by Game Publisher.
                        Registration on behalf of another person and inaccurate
                        giving information, then Game Publisher cannot protect
                        User information.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >10.USER are not allowed to use other USER Account ID ,
                        with or without knowledge from that USER.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >11.Responsibility for the confidentiality of ID and
                        Password are entirely within User itself. Game Publisher
                        have no responsibility for any data changing on USER ID
                        caused by hacking , cracking or other forms.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >12.USER are not allowed to make any business or
                        commercial by utilizing Game Publisher service, either
                        on an individual or group scale, unless it has obtained
                        permission from Game Publisher.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >13.Game Publisher RIGHT to block, remove and / or take
                        explicit action against ID if found to have committed
                        the following actions :</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体">（</font
                      ><font face="宋体"
                        >1）Use the Account ID, Character name, and or Guild
                        name with dirty words, abusive, disrespectful, obscene,
                        and / or offensive to religion, tribe and race..</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体">（</font
                      ><font face="宋体"
                        >2）Use the Account ID, Character name, and or Guild
                        name with GM , Operation, Management or Staff.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体">（</font
                      ><font face="宋体"
                        >3）Defamation or disseminate rumors / news that
                        discredit / discredit GM, Operator, Management and or
                        Game Publisher Staff for any purpose or purpose.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体">（</font
                      ><font face="宋体"
                        >4）Utilizing system weaknesses (bugs, flaws, errors) or
                        illegal software programs (third-party, illegal
                        software).</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体">（</font
                      ><font face="宋体"
                        >5）Defamation and or threatening other users physically
                        and mentally in the service.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体">（</font
                      ><font face="宋体"
                        >6）Doing hacking and or cracking against ID and or
                        other User Password.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体">（</font
                      ><font face="宋体"
                        >7）Creating fake reports on behalf of hacking, cracking
                        and / or fraud..</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体">（</font
                      ><font face="宋体"
                        >8）Perform fraud currency actions or any elemental type
                        (goods, items, characters) against other Users.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体">（</font
                      ><font face="宋体"
                        >9）Become a reseller and recipient of hacked items from
                        other Users.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体">（</font
                      ><font face="宋体"
                        >10）Selling any information data from other user.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体">（</font
                      ><font face="宋体"
                        >11）Collect or organize other Users to engage in
                        inappropriate, abusive, obscene or offensive
                        activities.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体">（</font
                      ><font face="宋体"
                        >12）Abusive, or other unpleasant deeds that have caused
                        other users inconvenience.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >14.Game Publisher DOES NOT advise, not recommend, and
                        shall not be liable for the following actions :</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体">（</font
                      ><font face="宋体"
                        >1）. Selling ID or Password</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体">（</font
                      ><font face="宋体"
                        >2）. Selling in Game Currency or any Elements (Goods ,
                        Items , Character).</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体">（</font
                      ><font face="宋体"
                        >3）. Trading any Elements (Goods, Items ,
                        Character).</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体">（</font
                      ><font face="宋体"
                        >4）. Doing Registration with inaccurate
                        information.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体">（</font
                      ><font face="宋体"
                        >5）. Sharing ID to other USER.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >15.For situations where User have connection problem /
                        lag and resulting in loss of free service elements
                        (goods, items, characters), Game Publisher is not
                        responsible for giving any compensation</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >16.Game Publisher reserves the right to restrict or
                        discontinue services caused by national emergencies,
                        natural disasters, termination of employment /
                        agreements with Game Publisher business partners,
                        etc.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >17.Game Publisher is not responsible for terminating
                        service elements (goods, items, characters) caused by
                        the following :</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >a. National emergency (war, natural disaster),
                        termination / termination of contract with Game
                        Publisher partners and others that are beyond the
                        capability of Game Publisher.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体">b. User Error.</font></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >c. Electrical problem or not get normal power
                        supply.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >18.Cash, Account number and item elements obtained in
                        the service are the responsibility of the User. All
                        forms of loss and or use done above cannot be returned
                        in any form of compensation.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >19.Users can extend the validity of Cash by buying and
                        refilling. If the validity period of Cash has expired
                        and is not replenished within 1 year, then Game
                        Publisher reserves the right to delete the User ID
                        Account without any notice.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >20.If the Cash amount entered is not in accordance with
                        the nominal value specified, then Cash is not refundable
                        in any form.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >21.Incoming Cash is not refundable if User does not use
                        the service until it expires</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >22.Game Publisher is not responsible if there is an
                        error in the process of sending cash by the User
                        itself.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >23.User agrees to releases Game Publisher's management,
                        staff and business partners from claims and losses
                        arising from actions, conduct, and within Game
                        Publisher's official service or website.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >24.Game Publisher is not responsible to give
                        compensating for the loss of free service elements
                        (goods, items, characters) provided.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >25.Game Publisher is not responsible for resolving
                        conflicts between Users and others. Game Publisher is
                        also not responsible for providing any compensation
                        incurred by this.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >26.Game Publisher is not responsible for the accuracy
                        and correctness of information on services, websites and
                        forums written by Users.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >27.If there any conflict between User and Game
                        Publisher in service matters, the settlement of disputes
                        can be resolved in accordance with law through the Local
                        District Court.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >HERE I REPRESENTATIVE TO READ AND UNDERSTAND ALL RULES
                        AND CONDITIONS SET FOR GAME PUBLISHER. USE OF GAME
                        PUBLISHER SERVICES IS ON FROM MY APPROVAL FOR THIS
                        APPEALS.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                </div>
              </template>
              <!-- 协议印尼 -->
              <template v-else>
                <div class="Section0">
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >Perjanjian Lisensi Pengguna Akhir</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >[Perjanjian Lisensi Pengguna Akhir]</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >Perjanjian Lisensi Pengguna Akhir ini adalah
                        persetujuan legal antara Anda (Pengguna) dengan penerbit
                        game (selanjutnya disebut "Penerbit Game"). Dengan
                        mengklik "Setuju"/"Daftar", Anda menyatakan persetujuan
                        Anda untuk mematuhi semua aturan dan kondisi yang
                        ditetapkan oleh Penerbit Game.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >Dengan menyetujui Perjanjian ini, Anda (yang akan
                        disebut Pengguna) secara hukum menyetujui untuk mematuhi
                        semua peraturan yang berlaku untuk semua konten/layanan
                        yang disediakan. Semua elemen yang terkait dengan
                        konten/konten yang disediakan oleh Penerbit Game
                        (gambar, cerita, informasi, dan karakter) merupakan
                        bagian dari hak cipta Penerbit Game dan rekan kerja
                        komersial mereka. Dengan menggunakan konten/layanan yang
                        disediakan oleh Penerbit Game, Pengguna secara otomatis
                        menyetujui aturan dan persetujuan yang ditetapkan oleh
                        Penerbit Game.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >1. Persyaratan ini merupakan aturan dasar untuk
                        penggunaan semua konten (game online) yang disediakan
                        oleh Penerbit Game. Penerbit Game memiliki hak
                        sepenuhnya atas hak publiksi dan hak operasi
                        layanan.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >2. Penerbit Game berhak untuk mengubah dan meninjau
                        ulang Perjanjian ini kapan saja. Pengguna harus secara
                        teratur memeriksa situs resmi yang disediakan oleh
                        Penerbit Game secara teratur untuk melihat dan meninjau
                        Perjanjian Hukum ini.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >3. Penerbit Game dapat mengubah sebagian atau seluruh
                        konten/konten dari layanan atau situs resmi tanpa
                        pemberitahuan atau tanpa pemberitahuan, untuk
                        meningkatkan kualitas layanan.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >4. Pengguna yang ingin menggunakan layanan Penerbit
                        Game harus mendaftar melalui situs resmi dan mengisi
                        data dengan akurat. Penerbit Game tidak bertanggung
                        jawab atas masalah apa pun yang timbul di kemudian hari
                        karena data pendaftaran yang tidak lengkap atau tidak
                        akurat.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >5. Penerbit Game memiliki kewajiban untuk menjaga
                        kerahasiaan semua informasi pribadi dan data Pengguna.
                        Penerbit Game tidak akan memberikan informasi ini kepada
                        pihak kecuali rekan kerja bisnis Penerbit Game dalam
                        menyediakan konten/layanan kepada Pengguna.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >6. Jika terjadi gangguan atau kualitas layanan yang
                        tidak memuaskan karena batasan atau kekurangan
                        fasilitas, Penerbit Game berhak untuk membatasi
                        penyediaan layanan kepada Pengguna hingga masalah
                        tersebut terselesaikan.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >7. Semua elemen dalam layanan Penerbit Game (item,
                        karakter) sepenuhnya dimiliki oleh Penerbit Game.
                        Pembelian dan penggunaan elemen seperti itu tidak
                        mewakili pengakuan atas properti oleh Pengguna,
                        melainkan hanya hak penggunaan dan hak penggunaan selama
                        Pengguna menggunakan layanan tersebut.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >8. Jika terdapat masalah dengan layanan yang
                        disediakan, Penerbit Game memiliki kewajiban untuk
                        memberitahu Pengguna melalui situs resmi Penerbit Game
                        tentang rencana perbaikan. Jika Pengguna menemukan
                        adanya masalah dengan layanan yang disediakan, mereka
                        berhak untuk memberikan informasi dalam cara atau wadah
                        yang disarankan oleh Penerbit Game.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >9. Jika Pengguna perlu mengubah informasi pribadi (kode
                        registrasi, email, kata sandi), mereka memiliki
                        kewajiban untuk melakukan perubahan tersebut melalui
                        situs resmi atau melalui metode lain yang disediakan
                        oleh Penerbit Game. Jika Pengguna mendaftarkan atas nama
                        orang lain dan informasi yang diberikan tidak akurat,
                        Penerbit Game tidak dapat melindungi informasi
                        Pengguna.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >10. Pengguna tidak diperbolehkan untuk menggunakan ID
                        akun Pengguna lain, apakah dengan pengetahuan atau tanpa
                        pengetahuan mereka.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >11. Tanggung jawab penuh atas kerahasiaan ID dan kata
                        sandi sepenuhnya berada pada Pengguna, dan Penerbit Game
                        tidak bertanggung jawab atas perubahan data ID Pengguna
                        yang disebabkan oleh serangan hacker, perebutan atau
                        bentuk lainnya.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >12. Pengguna tidak diperbolehkan untuk menggunakan
                        layanan Penerbit Game untuk melakukan kegiatan komersial
                        atau bisnis secara individual atau kelompok tanpa izin
                        tertulis dari Penerbit Game.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >13. Jika ditemukan bahwa ID memiliki perilaku berikut,
                        Penerbit Game berhak untuk memblokir, menghapus,
                        dan/atau mengambil tindakan tegas terhadapnya:</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >(1). Menggunakan akun ID, nama karakter, dan/atau nama
                        guild yang berisi kata-kata kasar, penghinaan, tidak
                        sopan, pornografi, dan/atau menghina agama, suku, dan
                        ras.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >(2). Menggunakan akun ID, nama karakter, dan/atau nama
                        guild dengan GM, Operation, Management, atau
                        Staff.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >(3). Dengan tujuan atau niat untuk menghina atau
                        menyebarkan rumor/berita yang merugikan/merusak GM,
                        operator, manajemen, dan/atau staf Penerbit Game.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >(4). Mengeksploitasi kelemahan sistem (kesalahan,
                        cacat, kesalahan) atau program perangkat lunak ilegal
                        (perangkat lunak pihak ketiga, ilegal).</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >(5). Menghina atau mengancam fisik dan mental Pengguna
                        lain dalam layanan.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >(6). Melakukan serangan hacker atau perebutan kata
                        sandi ID atau Pengguna lain.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >(7). Membuat laporan palsu untuk kegiatan hacker,
                        perebusan, dan/atau penipuan.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >(8). Melakukan kegiatan penipuan mata uang atau elemen
                        jenis apa pun (produk, item, karakter) terhadap Pengguna
                        lain.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >(9). Menjadi distributor dan penerima barang yang
                        dicuri oleh Pengguna lain.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >(10). Menjual informasi data apa pun dari Pengguna
                        lain.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >(11). Mengumpulkan atau mengorganisir Pengguna lain
                        untuk melakukan kegiatan yang tidak pantas, penghinaan,
                        pornografi, atau agresif.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >(12). Berperilaku kasar atau tidak menyenangkan,
                        menimbulkan ketidaknyamanan bagi Pengguna lain.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >14. Penerbit Game tidak menyarankan, tidak
                        merekomendasikan, dan tidak bertanggung jawab atas
                        perilaku berikut:</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >(1). Menjual ID atau kata sandi.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >(2). Menjual dengan mata uang game atau elemen apa pun
                        (produk, item, karakter).</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >(3). Bertransaksi elemen apa pun (produk, item,
                        karakter).</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >(4). Mendaftar dengan informasi yang tidak
                        akurat.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >(5). Berbagi ID dengan Pengguna lain.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >15. Penerbit Game tidak bertanggung jawab untuk
                        memberikan kompensasi atas kehilangan elemen layanan
                        gratis (produk, item, karakter) yang disebabkan oleh
                        masalah koneksi/keterlambatan Pengguna.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >16. Penerbit Game berhak untuk membatasi atau
                        menghentikan layanan karena situasi darurat nasional,
                        bencana alam, pengakhiran kontrak/pekerjaan dengan rekan
                        kerja bisnis Penerbit Game, dan alasan lain.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >17. Penerbit Game tidak bertanggung jawab untuk
                        menghentikan elemen layanan (produk, item, karakter)
                        karena:</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >a. Situasi darurat nasional (perang, bencana alam),
                        pengakhiran/pengakhiran kontrak dengan rekan kerja
                        bisnis Penerbit Game, dan situasi yang di luar kapasitas
                        Penerbit Game.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体">b. Kesalahan Pengguna.</font></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >c. Masalah listrik atau tidak mendapatkan sumber daya
                        listrik yang normal.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >18. Uang tunai, nomor rekening, dan elemen item di
                        layanan menjadi tanggung jawab Pengguna. Semua bentuk
                        kehilangan dan penggunaan di atas tidak akan diberikan
                        kembali dalam bentuk kompensasi.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >19. Pengguna dapat memperpanjang masa berlaku Cash
                        dengan membeli dan menabung, jika masa berlaku Cash
                        telah berakhir dan tidak diperpanjang dalam 1 tahun,
                        Penerbit Game berhak untuk menghapus akun ID Pengguna
                        tanpa pemberitahuan lebih lanjut.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >20. Uang tunai yang diinput tidak dapat dikembalikan
                        dalam bentuk apapun jika jumlahnya tidak sesuai dengan
                        nilai yang ditentukan.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >21. Uang tunai yang diperoleh tidak akan dikembalikan
                        jika Pengguna tidak menggunakan layanan sebelum
                        berakhirnya masa berlaku.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >22. Penerbit Game tidak bertanggung jawab atas
                        kesalahan yang dilakukan oleh Pengguna dalam proses
                        pengiriman uang tunai.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >23. Pengguna menyetujui untuk membebaskan manajemen,
                        karyawan, dan rekan kerja Penerbit Game dari klaim dan
                        kerugian yang timbul dari perilaku, tindakan, atau
                        kegagalan dalam layanan resmi Penerbit Game atau
                        situs.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >24. Penerbit Game tidak bertanggung jawab untuk
                        memberikan kompensasi atas kehilangan elemen layanan
                        gratis yang disediakan.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >25. Penerbit Game tidak bertanggung jawab untuk
                        menyelesaikan sengketa antara Pengguna dan pihak lain,
                        dan tidak bertanggung jawab untuk memberikan kompensasi
                        yang timbul dari situasi tersebut.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >26. Penerbit Game tidak bertanggung jawab atas akurasi
                        dan kebenaran informasi yang ditulis oleh Pengguna dalam
                        layanan, situs, dan forum.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >27. Jika terjadi sengketa antara Pengguna dan Penerbit
                        Game terkait layanan, dapat diselesaikan melalui
                        pengadilan lokal sesuai dengan undang-undang.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                  <p class="MsoPlainText">
                    <span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                      ><font face="宋体"
                        >Saya mewakili Anda telah membaca dan memahami semua
                        aturan dan kondisi Penerbit Game. Sejak tanggal
                        pemberian persetujuan ini, Anda dapat menggunakan
                        layanan Penerbit Game.</font
                      ></span
                    ><span
                      style="
                        mso-spacerun: 'yes';
                        font-family: 宋体;
                        mso-hansi-font-family: 'Courier New';
                        mso-bidi-font-family: 'Times New Roman';
                        font-size: 10.5pt;
                        mso-font-kerning: 1pt;
                      "
                    ></span>
                  </p>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 页脚 -->
    <div class="footer-box">
      <footerBox></footerBox>
    </div>
    <!-- 回到头部小图片 -->
    <toTop></toTop>
  </div>
</template>
<script>
import headerBox from "../components/Header-box/header-box.vue";
import footerBox from "../components/Footer-box/footer.vue";
import toTop from "../components/totop/totop.vue";

export default {
  name: "Mobile",
  components: {
    headerBox,
    footerBox,
    toTop,
  },
  data() {
    return {
      lang: "",
    };
  },
  mounted() {
    this.lang = localStorage.getItem("lang")
      ? localStorage.getItem("lang")
      : "en";
  },
};
</script>

<style lang="less" scoped>
.advise {
  .img {
    width: 100%;
    height: auto;
    object-fit: contain;
    cursor: default;
  }
}
// 标题
.head_line {
  padding-top: 36px;
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  .head_line_content {
    width: 1200px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    .name {
      font-weight: bold;
      font-size: 32px;
      color: #000000;
    }
  }
}
//协议
.Section0 {
  padding: 32px 0;
  p {
    margin-bottom: 12px;
  }
  a {
    color: -webkit-link;
    cursor: pointer;
    text-decoration: underline;
  }
}
</style>
