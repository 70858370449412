<template>
  <div>
    <!-- 首页导航 -->
    <div class="header-box">
      <headerBox navTable="3"></headerBox>
    </div>
    <div class="banner">
      <div class="new-header">
        <div class="header-contain">
          <div class="advise">
            <img class="img" src="/img/home12.svg" alt="" />
          </div>
          <!-- 标题 -->
          <div class="head_line">
            <div class="head_line_content">
              <div class="name">{{ $t("mobile.privacy_agreement") }}</div>
              <!-- 协议英文 -->
              <template v-if="lang == 'en'">
                <div class="Section0">
                  <h1>Privacy Policy</h1>
                  <div>Updated Date: <strong>2024/9/13</strong></div>
                  <div>Effective Date: <strong>2024/9/13</strong></div>

                  <h2>Introduction</h2>
                  <p>
                    <i>Blade Dance Legacy</i> is a product provided by
                    <i>Dawn (HK) TeChnology Co., Limited</i> (hereinafter
                    referred to as "we" or "us"). When you use our services, we
                    may collect and use your relevant information. We hope to
                    explain to you through this Privacy Policy how we collect,
                    use, store, and share this information when you use our
                    services, as well as the ways we provide for you to access,
                    update, control, and protect this information. This Privacy
                    Policy is closely related to the services you use with
                    <i>Blade Dance Legacy</i>, so please read it carefully and,
                    when necessary, follow the guidance of this Privacy Policy
                    to make appropriate choices. We try to present the relevant
                    technical terms involved in this Privacy Policy in a concise
                    and clear manner, and provide further explanation links to
                    facilitate your understanding.
                  </p>
                  <p>
                    <strong
                      >By using or continuing to use our services, you agree
                      that we collect, use, store, and share your relevant
                      information in accordance with this Privacy
                      Policy.</strong
                    >
                  </p>
                  <p>
                    If you have any questions about this Privacy Policy or
                    related matters, please contact us at
                    <strong
                      ><a href="mailto:kuaiyou878@gmail.com"
                        >kuaiyou878@gmail.com</a
                      ></strong
                    >.
                  </p>

                  <h2>1. Information We Collect</h2>
                  <p>
                    We or our third-party partners may collect, store, and use
                    the following information related to you when providing
                    services. If you do not provide relevant information, you
                    may not be able to register as our user or enjoy certain
                    services we provide, or achieve the intended effects of the
                    related services.
                  </p>

                  <ul>
                    <li>
                      <strong>Personal Information</strong>, such as the
                      personal information you provide to us when registering an
                      account or using our services, like your phone number,
                      email address, etc.
                    </li>
                    <li>
                      <strong>Location Information</strong>, referring to the
                      information about your location collected when you enable
                      location-based functions on your device and use services
                      based on location, including:
                      <ul>
                        <li>
                          Your geographic location information collected via GPS
                          or WiFi when using our services through mobile devices
                          with location functions;
                        </li>
                        <li>
                          You can stop the collection of your geographic
                          location information by turning off location
                          functions.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <strong>Log Information</strong>, referring to the
                      information collected by the system through cookies,
                      identifiers, and related technologies when you use our
                      services, including your
                      <strong>device information</strong>,
                      <strong>browsing information</strong>, and
                      <strong>click information</strong>, which will be stored
                      as log information to provide you with personalized user
                      experience and ensure service security. You can reject or
                      manage the use of cookies, identifiers, or related
                      technologies through browser settings.
                    </li>
                  </ul>

                  <p>The third-party SDKs we access:</p>
                  <p></p>
                  <ul>
                    <li>
                      <a
                        href="https://www.google.com/policies/privacy/"
                        target="_blank"
                        rel="noopener noreferrer"
                        >Google Play Services</a
                      >
                    </li>
                    <li>
                      <a
                        href="https://support.google.com/admob/answer/6128543?hl=en"
                        target="_blank"
                        rel="noopener noreferrer"
                        >AdMob</a
                      >
                    </li>
                    <li>
                      <a
                        href="https://firebase.google.com/support/privacy"
                        target="_blank"
                        rel="noopener noreferrer"
                        >Google Analytics for Firebase</a
                      >
                    </li>
                    <li>
                      <a
                        href="https://firebase.google.com/support/privacy/"
                        target="_blank"
                        rel="noopener noreferrer"
                        >Firebase Crashlytics</a
                      >
                    </li>
                    <li>
                      <a
                        href="https://www.facebook.com/about/privacy/update/printable"
                        target="_blank"
                        rel="noopener noreferrer"
                        >Facebook</a
                      >
                    </li>
                    <!----><!----><!----><!----><!----><!---->
                    <li>
                      <a
                        href="https://unity3d.com/legal/privacy-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                        >Unity</a
                      >
                    </li>
                    <!----><!----><!----><!----><!----><!----><!----><!----><!----><!---->
                    <li>
                      <a
                        href="https://www.adjust.com/terms/privacy-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                        >Adjust</a
                      >
                    </li>
                    <!----><!----><!----><!----><!----><!----><!----><!----><!---->
                  </ul>
                  <br />
                  <p></p>
                  <h2>2. Information Storage</h2>
                  <strong
                    >2.1 Methods and Duration of Information Storage</strong
                  >
                  <ul>
                    <li>
                      We will store your information in a secure manner,
                      including local storage (e.g., data caching through the
                      APP), databases, and server logs.
                    </li>
                    <li>
                      Generally, we only store your personal information for as
                      long as it is necessary to achieve the purposes of
                      providing services, or as required by laws and
                      regulations.
                    </li>
                  </ul>

                  <strong>2.2 Information Storage Location</strong>
                  <ul>
                    <li>
                      We will store the personal information collected within
                      the territory of China in accordance with the provisions
                      of laws and regulations.
                    </li>
                    <li>
                      Currently, we do not transfer or store your personal
                      information across borders. If we need to do so in the
                      future, we will inform you of the purpose, recipient,
                      security measures, and risks, and obtain your consent.
                    </li>
                  </ul>

                  <strong
                    >2.3 Notification When Products or Services Cease
                    Operations</strong
                  >
                  <ul>
                    <li>
                      In the event of termination of our products or services,
                      we will notify you via push notifications, announcements,
                      etc., and delete or anonymize your personal information
                      within a reasonable period, unless otherwise provided by
                      laws and regulations.
                    </li>
                  </ul>

                  <h2>3. Information Security</h2>
                  <p>
                    We use a variety of security technologies and procedures to
                    protect your information from loss, misuse, unauthorized
                    access, or disclosure. For example, in certain services, we
                    use encryption technologies (such as SSL) to protect the
                    personal information you provide. However, please understand
                    that due to technical limitations and the possibility of
                    various malicious means, it is impossible to guarantee 100%
                    security in the internet industry even with our best efforts
                    to enhance security measures. You need to understand that
                    the systems and communication networks you use to access our
                    services may encounter problems due to factors beyond our
                    control.
                  </p>

                  <h2>4. How We Use Information</h2>
                  <p>
                    We may use the information we collect during the process of
                    providing services for the following purposes:
                  </p>
                  <ul>
                    <li>To provide you with services;</li>
                    <li>
                      For identity verification, customer service, security
                      protection, fraud detection, archiving, and backup
                      purposes during the provision of services, to ensure the
                      security of the products and services we provide to you;
                    </li>
                    <li>
                      To help us design new services and improve our existing
                      services;
                    </li>
                    <li>
                      To better understand how you access and use our services,
                      to tailor responses to your personalized needs, such as
                      language settings, location settings, personalized help
                      services, and instructions, or to respond to you and other
                      users in other ways;
                    </li>
                    <li>
                      To provide you with more relevant advertisements in place
                      of generalized advertisements;
                    </li>
                    <li>
                      To evaluate and improve the effectiveness of
                      advertisements and other promotions and marketing
                      activities in our services;
                    </li>
                    <li>
                      For software certification or managing software updates;
                    </li>
                    <li>
                      To allow you to participate in surveys regarding our
                      products and services.
                    </li>
                  </ul>

                  <h2>5. Information Sharing</h2>
                  <p>
                    Currently, we do not proactively share or transfer your
                    personal information to third parties. If sharing or
                    transferring your personal information is necessary, or if
                    you require us to do so, we will obtain your explicit
                    consent or that of the third party.
                  </p>
                  <p>
                    To deliver advertisements, evaluate, and optimize
                    advertising effectiveness, we need to share some of your
                    data with advertisers and their agents and other third-party
                    partners. We require them to strictly adhere to our data
                    privacy protection measures and requirements, including but
                    not limited to processing according to data protection
                    agreements, promises, and related data processing policies
                    to avoid identifying individuals and protect privacy and
                    security.
                  </p>
                  <p>
                    We will not share personally identifiable information (e.g.,
                    your name or email address) with partners unless you
                    explicitly authorize us to do so.
                  </p>
                  <p>
                    We will not publicly disclose the personal information we
                    collect unless we have informed you of the purpose of the
                    disclosure, the type of information to be disclosed, and any
                    sensitive information involved, and obtained your explicit
                    consent.
                  </p>
                  <p>
                    As our business continues to develop, we may engage in
                    mergers, acquisitions, and asset transfers. In such cases,
                    we will inform you of the relevant circumstances and
                    continue to protect your personal information or require the
                    new controller to continue protecting it according to the
                    standards required by this Privacy Policy.
                  </p>
                  <p>
                    In addition, according to relevant laws, regulations, and
                    national standards, we may share, transfer, or publicly
                    disclose personal information without your prior consent in
                    the following circumstances:
                  </p>
                  <ul>
                    <li>
                      When it is related to national security or defense
                      security;
                    </li>
                    <li>
                      When it is related to public safety, public health, or
                      major public interests;
                    </li>
                    <li>
                      When it is related to criminal investigation, prosecution,
                      trial, and execution of judgments;
                    </li>
                    <li>
                      To protect the personal information subject or other
                      individuals' life, property, and other significant
                      legitimate rights and interests, but it is difficult to
                      obtain your consent;
                    </li>
                    <li>
                      When personal information is disclosed by the personal
                      information subject to the public;
                    </li>
                    <li>
                      When personal information is collected from legally
                      disclosed information, such as legitimate news reports,
                      government information disclosure, etc.
                    </li>
                  </ul>

                  <h2>6. Your Rights</h2>
                  <p>
                    During your use of our services, we may provide you with
                    appropriate settings based on specific product circumstances
                    to help you query, delete, correct, or withdraw your
                    personal information. You can refer to specific guidance for
                    operation. In addition, we have set up complaint and
                    reporting channels, and your opinions will be addressed in a
                    timely manner. If you cannot exercise your personal
                    information rights through the above methods, you can submit
                    a request to us via the contact information provided in this
                    Privacy Policy, and we will respond in accordance with legal
                    requirements.
                  </p>
                  <p>
                    When you decide to stop using our products or services, you
                    can contact us via email
                    <a href="mailto:kuaiyou878@gmail.com"
                      >kuaiyou878@gmail.com</a
                    >
                    to request account deletion. After deleting your account, we
                    will delete or anonymize your personal information unless
                    otherwise required by laws and regulations.
                  </p>

                  <h2>7. Changes</h2>
                  <p>
                    We may revise the terms of this Privacy Policy from time to
                    time. When changes occur, we will remind you of the new
                    Privacy Policy during version updates and inform you of the
                    effective date. Please carefully read the revised Privacy
                    Policy.
                    <strong
                      >If you continue to use our services, it means that you
                      agree to us handling your personal information in
                      accordance with the updated Privacy Policy.</strong
                    >
                  </p>

                  <h2>8. Protection of Minors</h2>
                  <p>
                    We encourage parents or guardians to guide minors under the
                    age of eighteen to use our services. We recommend that
                    minors encourage their parents or guardians to read this
                    Privacy Policy and seek their consent and guidance before
                    submitting personal information.
                  </p>
                  <p>
                    We does not use the Application to knowingly solicit data
                    from or market to children under the age of 13. We does not
                    knowingly collect personally identifiable information from
                    children under 13 years of age. In the case we discover that
                    a child under 13 has provided personal information, we will
                    immediately delete this from their servers. If you are a
                    parent or guardian and you are aware that your child has
                    provided us with personal information, please contact us (<a
                      href="mailto:kuaiyou878@gmail.com"
                      >kuaiyou878@gmail.com</a
                    >) so that they will be able to take the necessary actions.
                  </p>

                  <h2>9. Contact Us</h2>
                  <p>
                    If you have any questions regarding privacy while using the
                    Application, or have questions about the practices, please
                    contact us via email at
                    <a href="mailto:kuaiyou878@gmail.com"
                      >kuaiyou878@gmail.com</a
                    >.
                  </p>
                </div>
              </template>
              <!-- 协议印尼 -->
              <template v-else>
                <div class="Section0">
                  <h1>Kebijakan Privasi</h1>
                  <div>Tanggal Diperbarui: <strong>2024/9/13</strong></div>
                  <div>Tanggal Efektif: <strong>2024/9/13</strong></div>

                  <h2>Pendahuluan</h2>
                  <p>
                    <i>Blade Dance Legacy</i> adalah produk yang disediakan oleh
                    <i>Dawn (HK) TeChnology Co., Limited</i> (selanjutnya
                    disebut sebagai "kami"). Saat Anda menggunakan layanan kami,
                    kami dapat mengumpulkan dan menggunakan informasi Anda yang
                    relevan. Kami berharap dapat menjelaskan kepada Anda melalui
                    Kebijakan Privasi ini bagaimana kami mengumpulkan,
                    menggunakan, menyimpan, dan membagikan informasi ini saat
                    Anda menggunakan layanan kami, serta cara yang kami sediakan
                    bagi Anda untuk mengakses, memperbarui, mengontrol, dan
                    melindungi informasi ini. Kebijakan Privasi ini terkait erat
                    dengan layanan yang Anda gunakan bersama
                    <i>Blade Dance Legacy</i>, jadi harap baca dengan saksama
                    dan, bila perlu, ikuti panduan Kebijakan Privasi ini untuk
                    membuat pilihan yang tepat. Kami mencoba menyajikan istilah
                    teknis yang relevan yang terlibat dalam Kebijakan Privasi
                    ini secara ringkas dan jelas, dan menyediakan tautan
                    penjelasan lebih lanjut untuk memudahkan pemahaman Anda.
                  </p>
                  <p>
                    <strong
                      >Dengan menggunakan atau terus menggunakan layanan kami,
                      Anda setuju bahwa kami mengumpulkan, menggunakan,
                      menyimpan, dan membagikan informasi Anda yang relevan
                      sesuai dengan Kebijakan Privasi ini.</strong
                    >
                  </p>
                  <p>
                    Jika Anda memiliki pertanyaan tentang Kebijakan Privasi ini
                    atau hal-hal terkait, silakan hubungi kami di
                    <strong
                      ><a href="mailto:kuaiyou878@gmail.com"
                        >kuaiyou878@gmail.com</a
                      ></strong
                    >.
                  </p>

                  <h2>1. Informasi yang Kami Kumpulkan</h2>
                  <p>
                    Kami atau mitra pihak ketiga kami dapat mengumpulkan,
                    menyimpan, dan menggunakan informasi berikut yang terkait
                    dengan Anda saat menyediakan layanan. Jika Anda tidak
                    memberikan informasi yang relevan, Anda mungkin tidak dapat
                    mendaftar sebagai pengguna kami atau menikmati layanan
                    tertentu yang kami sediakan, atau mencapai efek yang
                    diinginkan dari layanan terkait.
                  </p>

                  <ul>
                    <li>
                      <strong>Informasi Pribadi</strong>, seperti informasi
                      pribadi yang Anda berikan kepada kami saat mendaftar akun
                      atau menggunakan layanan kami, seperti nomor telepon,
                      alamat email, dll.
                    </li>
                    <li>
                      <strong>Informasi Lokasi</strong>, mengacu pada informasi
                      tentang lokasi Anda yang dikumpulkan saat Anda
                      mengaktifkan fungsi berbasis lokasi di perangkat Anda dan
                      menggunakan layanan berdasarkan lokasi, termasuk:
                      <ul>
                        <li>
                          Informasi lokasi geografis Anda yang dikumpulkan
                          melalui GPS atau WiFi saat menggunakan layanan kami
                          melalui perangkat seluler dengan fungsi lokasi;
                        </li>
                        <li>
                          Anda dapat menghentikan pengumpulan informasi lokasi
                          geografis Anda dengan menonaktifkan fungsi lokasi.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <strong>Informasi Log</strong>, mengacu pada informasi
                      yang dikumpulkan oleh sistem melalui cookie, pengenal, dan
                      teknologi terkait saat Anda menggunakan layanan kami,
                      termasuk <strong>informasi perangkat</strong>,
                      <strong>informasi penelusuran</strong>, dan
                      <strong>klik informasi</strong>, yang akan disimpan
                      sebagai informasi log untuk memberi Anda pengalaman
                      pengguna yang dipersonalisasi dan memastikan keamanan
                      layanan. Anda dapat menolak atau mengelola penggunaan
                      cookie, pengenal, atau teknologi terkait melalui setelan
                      browser.
                    </li>
                  </ul>

                  <p>SDK pihak ketiga yang kami akses:</p>
                  <p></p>
                  <ul>
                    <li>
                      <a
                        href="https://www.google.com/policies/privacy/"
                        target="_blank"
                        rel="noopener noreferrer"
                        >Layanan Google Play</a
                      >
                    </li>
                    <li>
                      <a
                        href="https://support.google.com/admob/answer/6128543?hl=id"
                        target="_blank"
                        rel="noopener noreferrer"
                        >AdMob</a
                      >
                    </li>
                    <li>
                      <a
                        href="https://firebase.google.com/support/privacy"
                        target="_blank"
                        rel="noopener noreferrer"
                        >Google Analytics untuk Firebase</a
                      >
                    </li>
                    <li>
                      <a
                        href="https://firebase.google.com/support/privacy/"
                        target="_blank"
                        rel="noopener noreferrer"
                        >Firebase Crashlytics</a
                      >
                    </li>
                    <li>
                      <a
                        href="https://www.facebook.com/tentang/privasi/pembaruan/dapat dicetak"
                        target="_blank"
                        rel="tidak ada pembukaan noreferrer"
                        >Facebook</a
                      >
                    </li>
                    <!----><!----><!----><!----><!----><!---->
                    <li>
                      <a
                        href="https://unity3d.com/legal/kebijakan-privasi"
                        target="_blank"
                        rel="tidak ada pembukaan noreferrer"
                        >Unity</a
                      >
                    </li>
                    <!----><!----><!----><!----><!----><!----><!----><!----><!----><!---->
                    <li>
                      <a
                        href="https://www.adjust.com/persyaratan/kebijakan-privasi"
                        target="_blank"
                        rel="tidak ada pembukaan noreferrer"
                        >Sesuaikan</a
                      >
                    </li>
                    <!----><!----><!----><!----><!----><!----><!----><!----><!---->
                  </ul>
                  <br />
                  <p></p>
                  <h2>2. Penyimpanan Informasi</h2>
                  <strong>2.1 Metode dan Durasi Penyimpanan Informasi</strong>
                  <ul>
                    <li>
                      Kami akan menyimpan informasi Anda dengan cara yang aman,
                      termasuk penyimpanan lokal (misalnya, penyimpanan data
                      melalui APP), basis data, dan log server.
                    </li>
                    <li>
                      Umumnya, kami hanya menyimpan informasi pribadi Anda
                      selama diperlukan untuk mencapai tujuan penyediaan
                      layanan, atau sebagaimana diwajibkan oleh hukum dan
                      peraturan.
                    </li>
                  </ul>

                  <strong>2.2 Lokasi Penyimpanan Informasi</strong>
                  <ul>
                    <li>
                      Kami akan menyimpan informasi pribadi yang dikumpulkan di
                      wilayah Tiongkok sesuai dengan ketentuan hukum dan
                      peraturan.
                    </li>
                    <li>
                      Saat ini, kami tidak mentransfer atau menyimpan informasi
                      pribadi Anda lintas batas. Jika kami perlu melakukannya di
                      masa mendatang, kami akan memberi tahu Anda tentang
                      tujuan, penerima, langkah-langkah keamanan, dan risiko,
                      serta memperoleh persetujuan Anda.
                    </li>
                  </ul>

                  <strong
                    >2.3 Pemberitahuan Saat Produk atau Layanan Berhenti
                    Beroperasi</strong
                  >
                  <ul>
                    <li>
                      Jika terjadi penghentian produk atau layanan kami, kami
                      akan memberi tahu Anda melalui pemberitahuan push,
                      pengumuman, dll., dan menghapus atau menganonimkan
                      informasi pribadi Anda dalam jangka waktu yang wajar,
                      kecuali ditentukan lain oleh undang-undang dan peraturan.
                    </li>
                  </ul>

                  <h2>3. Keamanan Informasi</h2>
                  <p>
                    Kami menggunakan berbagai teknologi dan prosedur keamanan
                    untuk melindungi informasi Anda dari kehilangan,
                    penyalahgunaan, akses tidak sah, atau pengungkapan.
                    Misalnya, dalam layanan tertentu, kami menggunakan teknologi
                    enkripsi (seperti SSL) untuk melindungi informasi pribadi
                    yang Anda berikan. Namun, harap dipahami bahwa karena
                    keterbatasan teknis dan kemungkinan berbagai cara jahat,
                    tidak mungkin untuk menjamin keamanan 100% dalam industri
                    internet bahkan dengan upaya terbaik kami untuk meningkatkan
                    langkah-langkah keamanan. Anda perlu memahami bahwa sistem
                    dan jaringan komunikasi yang Anda gunakan untuk mengakses
                    layanan kami dapat mengalami masalah karena faktor-faktor di
                    luar kendali kami.
                  </p>

                  <h2>4. Cara Kami Menggunakan Informasi</h2>
                  <p>
                    Kami dapat menggunakan informasi yang kami kumpulkan selama
                    proses penyediaan layanan untuk tujuan berikut:
                  </p>
                  <ul>
                    <li>Untuk menyediakan layanan bagi Anda;</li>
                    <li>
                      Untuk verifikasi identitas, layanan pelanggan,
                      perlindungan keamanan, deteksi penipuan, pengarsipan, dan
                      tujuan pencadangan selama penyediaan layanan, untuk
                      memastikan keamanan produk dan layanan yang kami berikan
                      kepada Anda;
                    </li>
                    <li>
                      Untuk membantu kami merancang layanan baru dan
                      meningkatkan layanan kami yang sudah ada;
                    </li>
                    <li>
                      Untuk lebih memahami cara Anda mengakses dan menggunakan
                      layanan kami, untuk menyesuaikan respons dengan kebutuhan
                      pribadi Anda, seperti pengaturan bahasa, pengaturan
                      lokasi, layanan bantuan pribadi, dan instruksi, atau untuk
                      menanggapi Anda dan pengguna lain dengan cara lain;
                    </li>
                    <li>
                      Untuk menyediakan iklan yang lebih relevan bagi Anda
                      sebagai pengganti iklan umum;
                    </li>
                    <li>
                      Untuk mengevaluasi dan meningkatkan efektivitas iklan dan
                      promosi serta aktivitas pemasaran lainnya dalam layanan
                      kami;
                    </li>
                    <li>
                      Untuk sertifikasi perangkat lunak atau mengelola pembaruan
                      perangkat lunak;
                    </li>
                    <li>
                      Untuk memungkinkan Anda berpartisipasi dalam survei
                      mengenai produk dan layanan kami.
                    </li>
                  </ul>

                  <h2>5. Berbagi Informasi</h2>
                  <p>
                    Saat ini, kami tidak secara proaktif membagikan atau
                    mentransfer informasi pribadi Anda ke pihak ketiga. Jika
                    berbagi atau mentransfer informasi pribadi Anda diperlukan,
                    atau jika Anda mengharuskan kami melakukannya, kami akan
                    memperoleh persetujuan tegas dari Anda atau pihak ketiga.
                  </p>
                  <p>
                    Untuk menayangkan iklan, mengevaluasi, dan mengoptimalkan
                    efektivitas iklan, kami perlu membagikan sebagian data Anda
                    dengan pengiklan dan agen mereka serta mitra pihak ketiga
                    lainnya. Kami mengharuskan mereka untuk benar-benar mematuhi
                    langkah-langkah dan persyaratan perlindungan privasi data
                    kami, termasuk namun tidak terbatas pada pemrosesan sesuai
                    dengan perjanjian perlindungan data, janji, dan kebijakan
                    pemrosesan data terkait untuk menghindari identifikasi
                    individu dan melindungi privasi dan keamanan.
                  </p>
                  <p>
                    Kami tidak akan membagikan informasi yang dapat
                    diidentifikasi secara pribadi (misalnya, nama atau alamat
                    email Anda) dengan mitra kecuali Anda secara tegas
                    mengizinkan kami untuk melakukannya.
                  </p>
                  <p>
                    Kami tidak akan mengungkapkan informasi pribadi yang kami
                    kumpulkan kepada publik kecuali kami telah memberi tahu Anda
                    tentang tujuan pengungkapan, jenis informasi yang akan
                    diungkapkan, dan informasi sensitif apa pun yang terlibat,
                    dan memperoleh persetujuan tegas dari Anda.
                  </p>
                  <p>
                    Seiring dengan terus berkembangnya bisnis kami, kami dapat
                    terlibat dalam merger, akuisisi, dan transfer aset. Dalam
                    kasus tersebut, kami akan memberi tahu Anda tentang keadaan
                    yang relevan dan terus melindungi informasi pribadi Anda
                    atau meminta pengendali baru untuk terus melindunginya
                    sesuai dengan standar yang diwajibkan oleh Kebijakan Privasi
                    ini.
                  </p>
                  <p>
                    Selain itu, menurut undang-undang, peraturan, dan standar
                    nasional yang relevan, kami dapat membagikan, mentransfer,
                    atau mengungkapkan informasi pribadi kepada publik tanpa
                    persetujuan Anda sebelumnya dalam keadaan berikut:
                  </p>
                  <ul>
                    <li>
                      Jika terkait dengan keamanan nasional atau keamanan
                      pertahanan;
                    </li>
                    <li>
                      Jika terkait dengan keselamatan publik, kesehatan publik,
                      atau kepentingan publik yang utama;
                    </li>
                    <li>
                      Jika terkait dengan penyelidikan kriminal, penuntutan,
                      persidangan, dan pelaksanaan putusan;
                    </li>
                    <li>
                      Untuk melindungi subjek informasi pribadi atau kehidupan,
                      properti, dan hak serta kepentingan sah penting lainnya
                      dari individu lain, tetapi sulit untuk mendapatkan
                      persetujuan Anda;
                    </li>
                    <li>
                      Jika informasi pribadi diungkapkan oleh subjek informasi
                      pribadi kepada publik;
                    </li>
                    <li>
                      Jika informasi pribadi dikumpulkan dari informasi yang
                      diungkapkan secara sah, seperti laporan berita yang sah,
                      pengungkapan informasi pemerintah, dll.
                    </li>
                  </ul>

                  <h2>6. Hak Anda</h2>
                  <p>
                    Selama Anda menggunakan layanan kami, kami dapat memberi
                    Anda pengaturan yang sesuai berdasarkan keadaan produk
                    tertentu untuk membantu Anda menanyakan, menghapus,
                    mengoreksi, atau menarik informasi pribadi Anda. Anda dapat
                    merujuk ke panduan khusus untuk pengoperasian. Selain itu,
                    kami telah menyiapkan saluran pengaduan dan pelaporan, dan
                    pendapat Anda akan segera ditanggapi. Jika Anda tidak dapat
                    menggunakan hak informasi pribadi Anda melalui metode di
                    atas, Anda dapat mengajukan permintaan kepada kami melalui
                    informasi kontak yang disediakan dalam Kebijakan Privasi
                    ini, dan kami akan menanggapinya sesuai dengan persyaratan
                    hukum.
                  </p>
                  <p>
                    Jika Anda memutuskan untuk berhenti menggunakan produk atau
                    layanan kami, Anda dapat menghubungi kami melalui email
                    <a href="mailto:kuaiyou878@gmail.com"
                      >kuaiyou878@gmail.com</a
                    >
                    untuk meminta penghapusan akun. Setelah menghapus akun Anda,
                    kami akan menghapus atau menganonimkan informasi pribadi
                    Anda kecuali jika diwajibkan oleh hukum dan peraturan.
                  </p>

                  <h2>7. Perubahan</h2>
                  <p>
                    Kami dapat merevisi ketentuan Kebijakan Privasi ini dari
                    waktu ke waktu. Jika terjadi perubahan, kami akan
                    mengingatkan Anda tentang Kebijakan Privasi yang baru selama
                    pembaruan versi dan memberi tahu Anda tentang tanggal
                    efektifnya. Harap baca Kebijakan Privasi yang direvisi
                    dengan saksama.
                    <strong
                      >Jika Anda terus menggunakan layanan kami, berarti Anda
                      setuju agar kami menangani informasi pribadi Anda sesuai
                      dengan Kebijakan Privasi yang diperbarui.</strong
                    >
                  </p>

                  <h2>8. Perlindungan Anak di Bawah Umur</h2>
                  <p>
                    Kami menganjurkan orang tua atau wali untuk membimbing anak
                    di bawah umur yang berusia di bawah delapan belas tahun
                    untuk menggunakan layanan kami. Kami menyarankan agar anak
                    di bawah umur mendorong orang tua atau wali mereka untuk
                    membaca Kebijakan Privasi ini dan meminta persetujuan serta
                    bimbingan mereka sebelum mengirimkan informasi pribadi.
                  </p>
                  <p>
                    Kami tidak menggunakan Aplikasi untuk secara sengaja meminta
                    data dari atau memasarkan kepada anak di bawah usia 13
                    tahun. Kami tidak secara sengaja mengumpulkan informasi yang
                    dapat diidentifikasi secara pribadi dari anak di bawah usia
                    13 tahun. Jika kami menemukan bahwa seorang anak di bawah
                    usia 13 tahun telah memberikan informasi pribadi, kami akan
                    segera menghapusnya dari server mereka. Jika Anda adalah
                    orang tua atau wali dan Anda mengetahui bahwa anak Anda
                    telah memberikan kami informasi pribadi, silakan hubungi
                    kami (<a href="mailto:kuaiyou878@gmail.com"
                      >kuaiyou878@gmail.com</a
                    >) agar mereka dapat mengambil tindakan yang diperlukan.
                  </p>

                  <h2>9. Hubungi Kami</h2>
                  <p>
                    Jika Anda memiliki pertanyaan tentang privasi saat
                    menggunakan Aplikasi, atau memiliki pertanyaan tentang
                    praktiknya, silakan hubungi kami melalui email di
                    <a href="mailto:kuaiyou878@gmail.com"
                      >kuaiyou878@gmail.com</a
                    >.
                  </p>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 页脚 -->
    <div class="footer-box">
      <footerBox></footerBox>
    </div>
    <!-- 回到头部小图片 -->
    <toTop></toTop>
  </div>
</template>
<script>
import headerBox from "../components/Header-box/header-box.vue";
import footerBox from "../components/Footer-box/footer.vue";
import toTop from "../components/totop/totop.vue";

export default {
  name: "Mobile",
  components: {
    headerBox,
    footerBox,
    toTop,
  },
  data() {
    return {
      lang: "",
    };
  },
  mounted() {
    this.lang = localStorage.getItem("lang")
      ? localStorage.getItem("lang")
      : "en";
  },
};
</script>

<style lang="less" scoped>
.advise {
  .img {
    width: 100%;
    height: auto;
    object-fit: contain;
    cursor: default;
  }
}
// 标题
.head_line {
  padding-top: 36px;
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  .head_line_content {
    width: 1200px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    .name {
      font-weight: bold;
      font-size: 32px;
      color: #000000;
    }
  }
}
//协议
.Section0 {
  padding: 32px 0;
  h1,
  h2,
  p {
    font-size: 18px !important;
  }
  a {
    color: -webkit-link;
    cursor: pointer;
    text-decoration: underline;
  }
}
</style>
