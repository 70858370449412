<template>
  <div>
    <!-- 定义路由来匹配要显示的组件 -->
    <router-view></router-view>
  </div>
</template>

<script>
import copy2clipboard from "@/utils/copy2clipboard";
import urlUtils from "@/utils/urlUtils";
import { viewContent } from "@/api/download";
export default {
  name: "App",

  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="less" scoped>
// ::-webkit-scrollbar {
//   /*隐藏滚轮*/
//   display: none;
// }
</style>>
