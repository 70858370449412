export default {
    mobile: {
        home: 'Home',
        usr_agreement: 'User agreement',
        terms_conditions: 'Terms & Conditions',
        privacy_agreement: 'Privacy Policy',
        account_request: 'Account deletion request',
        title: '《 Blade Dance Legacy 》',
        name: 'Game Description',
        info: '《 Blade Dance Legacy 》is an MMORPG game with the theme of oriental fairy chivalry. The game adopts Unity 3D engine technology research and development, fusion of rock elements and fairy elements, the first "rock fairy" concept. The game is rich in gameplay with PVP and PVE gameplay such as camp battle, immortal league, Tianxuan battlefield, chaos battlefield, wilderness demon-subduing, war soul hall, etc., and various cultivation elements such as pets, mounts, gems, equipment, etc. Meanwhile, it also introduces social gameplay such as real-time chat, love relationship system, and beauty photo.',
        about: 'Contact us',
        img01: '/img/home01.svg',
        img02: '/img/home14.svg',























    },

}