<template>
  <div class="to_top" @click="gettop">
    <img class="img-customer" :src="$t('mobile.img02')" alt="" />
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import { v1Share } from "@/api/customer";
import urlUtils from "@/utils/urlUtils";

export default {
  name: "Totop",
  props: {
    customer: {
      type: String,
      default: "",
    },
  },
  components: {
    QRCode,
  },

  methods: {
    // 用定时器来制作缓动效果
    gettop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop =
          document.documentElement.scrollTop =
          top -=
            50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 15);
    },
    // 用来控制回到头部的图片的显示和隐藏
    getScroll() {
      this.height = document.body.offsetHeight / 4;
      this.top = document.documentElement.scrollTop || document.body.scrollTop;
      if (this.top >= this.height) {
        this.toTopIsShow = true;
      } else if (this.top < this.height) {
        this.toTopIsShow = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.to-top {
  width: 142px;
  padding-top: 8px;
  padding-bottom: 16px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 3px 3px 3px 3px;
  position: fixed;
  bottom: 170px;
  right: 52px;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.scan-box {
  flex: none;
  width: 142px;
  height: 157px;
  background: url("/img/appiconbackground.png") no-repeat;
  position: relative;
  border-bottom: 1px solid rgba(255, 255, 255, 0.28);
  .codeimg {
    position: absolute;
    top: 20px;
    left: 21px;
    width: 96px;
    height: 95px;
    padding: 4px;
    background: #fff;
    display: flex;
    align-content: center;
    justify-content: center;
  }
  .name {
    font-size: 15px;
    color: #fff;
    position: absolute;
    left: 18px;
    bottom: 6px;
  }
}

.to_top {
  width: 54px;
  height: 54px;
  background-color: #f2f2f2;
  position: fixed;
  z-index: 9999999;
  top: 80%;
  right: 64px;
  cursor: pointer;
  transition: all 0.3s ease;
  opacity: 0.5;
  border-radius: 50%;
  text-align: center;
  .img-customer {
    width: 54px;
    height: 54px;
    object-fit: cover;
  }
}

// .to-top:hover ~ .scan-box {
//   opacity: 1;
//   transition: 2s;
// }

.to-top2 {
  width: 81px;
  height: 81px;
  background: #ffffff;
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.09);
  border-radius: 5px 5px 5px 5px;
  opacity: 1;
  position: fixed;
  bottom: 125px;
  right: 52px;

  z-index: 10000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  color: #c2c2c2;
  .text {
    margin-top: 5px;
  }

  img {
    width: 41px;
    height: 41px;
    object-fit: cover;
  }
}

.to-top3 {
  width: 81px;
  height: 81px;
  background: #ffffff;
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.09);
  border-radius: 5px 5px 5px 5px;
  opacity: 1;
  position: fixed;
  bottom: 35px;
  right: 52px;

  z-index: 10000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  color: #c2c2c2;
  .text {
    margin-top: 5px;
  }

  img {
    width: 41px;
    height: 41px;
    object-fit: cover;
  }
}
</style>
