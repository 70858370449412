<template>
  <div id="app">
    <!-- 首页导航 -->
    <div class="header-box">
      <headerBox navTable="1"></headerBox>
    </div>
    <!-- 首页背景加主体 -->
    <div class="home-box">
      <homeBox></homeBox>
      <contentBox></contentBox>
    </div>
    <!-- 页脚 -->
    <div class="footer-box">
      <footerBox :ishome="ishome"></footerBox>
    </div>
    <!-- 回到头部小图片 -->
    <toTop></toTop>
  </div>
</template>

<script>
import headerBox from "../components/Header-box/header-box.vue";
import homeBox from "../components/Home-Box/homebox.vue";
import contentBox from "../components/Home-Box/content.vue";
import footerBox from "../components/Footer-box/footer.vue";
import toTop from "../components/totop/totop.vue";
import { getCustomerService } from "@/api/customer";
export default {
  name: "Home",
  data() {
    return {
      //判断是否是首页,改变页脚
      ishome: "",
      customer: "", //客服
    };
  },
  components: {
    headerBox,
    homeBox,
    contentBox,
    footerBox,
    toTop,
  },

  created() {
    if (this.$route.path === "/home") {
      this.ishome = false;
    }
    const id = "pathIdentification";
    localStorage.setItem("path", id);
  },
  destroyed() {
    localStorage.removeItem("path");
  },
};
</script>

<style lang="less" scoped>
#app {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.home-box {
  background: #fff;
}
</style>
