<template>
  <div class="home-content">
    <div class="content_center">
      <!-- 使用seo如何节省 -->
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import vueSeamlessScroll from "vue-seamless-scroll";
export default {
  name: "Content",
  components: {
    swiper,
    swiperSlide,
    vueSeamlessScroll,
  },
  data() {
    return {};
  },
  methods: {
    gohomePage() {
      let ext = this.$cookies.get("aiseo_channel_ext");
      if (ext) {
        window.open("https://www.aiseo.net?ext=" + ext);
      } else {
        window.open("https://www.aiseo.net");
      }
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep .el-carousel__arrow--left {
  display: none !important;
}
::v-deep .el-carousel__arrow--right {
  display: none !important;
}
//未进入此页面的样式
::v-deep .el-carousel__button {
  width: 32px;
  height: 6px;
  background: #d9d9d9;
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
}
//滚动到此页面的样式
::v-deep .is-active .el-carousel__button {
  width: 32px;
  height: 6px;
  background: #8158dd;
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
}
//指示点位置
::v-deep .el-carousel__indicators {
  position: absolute;
  left: 8px;
  bottom: 40px;
}
//轮播图容器
::v-deep .el-carousel__container {
  width: 100%;
  height: 612px;
}
</style>




